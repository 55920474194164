import { Signal, computed } from '@angular/core';
import {
  SignalStoreFeature,
  signalStoreFeature,
  withComputed,
  withState,
} from '@ngrx/signals';

export type RequestStatus = 'init' | 'loading' | 'loaded' | { error: string };
export type RequestStatusState = { requestStatus: RequestStatus };

export type NamedRequestStatusState<Collection extends string> = {
  [K in Collection as `${K}RequestStatus`]: RequestStatus;
};

export type NamedRequestStatusSignals<Prop extends string> = {
  [K in Prop as `${K}Loading`]: Signal<boolean>;
} & {
  [K in Prop as `${K}Loaded`]: Signal<boolean>;
} & {
  [K in Prop as `${K}Error`]: Signal<string | null>;
};

export type RequestStatuseSignals = {
  loading: Signal<boolean>;
  loaded: Signal<boolean>;
  error: Signal<string | null>;
};

export function getRequestStatusKeys(config?: { collection?: string }): Record<string, string> {
  const prop = config?.collection;
  return {
    requestStatusKey: prop ? `${config.collection}RequestStatus` : 'requestStatus',
    loadingKey: prop ? `${config.collection}Loading` : 'loading',
    loadedKey: prop ? `${config.collection}Loaded` : 'loaded',
    errorKey: prop ? `${config.collection}Error` : 'error',
  };
}

export function withRequestState<Collection extends string>(config: {
  collection: Collection;
}): SignalStoreFeature<
  { state: NonNullable<unknown>; computed: NonNullable<unknown>; methods: NonNullable<unknown> },
  {
    state: NamedRequestStatusState<Collection>;
    computed: NamedRequestStatusSignals<Collection>;
    methods: NonNullable<unknown>;
  }
>;
export function withRequestState(): SignalStoreFeature<
  { state: NonNullable<unknown>; computed: NonNullable<unknown>; methods: NonNullable<unknown> },
  {
    state: RequestStatusState;
    computed: RequestStatuseSignals;
    methods: NonNullable<unknown>;
  }
>;
export function withRequestState<Collection extends string>(config?: {collection: Collection}): SignalStoreFeature {
  const { requestStatusKey, errorKey, loadedKey, loadingKey } = getRequestStatusKeys(config);
  return signalStoreFeature(
    withState({ [requestStatusKey]: 'init' }),
    withComputed(state => {
      const requestStatus = state[requestStatusKey] as Signal<RequestStatus>;

      return {
        [loadingKey]: computed(() => requestStatus() === 'loading'),
        [loadedKey]: computed(() => requestStatus() === 'loaded'),
        [errorKey]: computed(() => {
          const state = requestStatus();
          return typeof state === 'object' ? state.error : null;
        }),
      };
    }),
  );
}

export function setLoading<Prop extends string>(prop: Prop): NamedRequestStatusState<Prop>;
export function setLoading(): RequestStatusState;
export function setLoading<Prop extends string>(prop?: Prop): NamedRequestStatusState<Prop> | RequestStatusState {
  if (prop) {
    return { [`${prop}RequestStatus`]: 'loading' } as NamedRequestStatusState<Prop>;
  }
  return { requestStatus: 'loading' };
}

export function setLoaded<Prop extends string>(prop: Prop): NamedRequestStatusState<Prop>;
export function setLoaded(): RequestStatusState;
export function setLoaded<Prop extends string>(prop?: Prop): NamedRequestStatusState<Prop> | RequestStatusState {
  if (prop) {
    return { [`${prop}RequestStatus`]: 'loaded' } as NamedRequestStatusState<Prop>;
  }
  return { requestStatus: 'loaded' };
}

export function setError<Prop extends string>(error: string, prop: Prop): NamedRequestStatusState<Prop>;
export function setError(error: string): RequestStatusState;
export function setError<Prop extends string>(error: string, prop?: Prop): NamedRequestStatusState<Prop> | RequestStatusState {
  if (prop) {
    return { [`${prop}RequestStatus`]: { error } } as NamedRequestStatusState<Prop>;
  }
  return { requestStatus: { error } };
}
